(function ($) {
  $(function () {
    var $banner = $('#cookie-banner').on('click', 'a, button', hide);

    function show() {
      $banner.removeAttr('hidden');
    }

    function hide() {
      $banner.attr('hidden', true);
      localStorage.setItem('cookieBanner', 'hidden');
    }

    if (localStorage.getItem('cookieBanner') === null) {
      show();
    }
  });
})(jQuery);
