(function ($) {
  $(document)
    .on('click.dropdown', '.dropdown__head', function (event) {
      $(this)
        .filter('[data-dropdown-toggle]:not(.is-active)')
        .each(function () {
          $($(this).data('dropdown-toggle')).closest('.dropdown').find('.dropdown__head').trigger('click.dropdown');
        })
        .end()
        .closest('.dropdown')
        .find('.dropdown__body')
        .removeClass('is-hidden')
        .end()
        .delay(10)
        .queue(function (next) {
          $(this).toggleClass('is-active');
          next();
        });
    })
    .on('transitionend.dropdown', '.dropdown__body', function (event) {
      $(this).toggleClass('is-hidden', !$(this).closest('.dropdown').hasClass('is-active'));
    });
})(jQuery);
