(function ($) {
  function tabsHeadOnClick(event) {
    var $this = $(this),
      $body = $('#' + $this.attr('aria-controls')),
      $tabs = $this.closest('.tabs');

    $tabs
      .find('.tabs__head.is-active, .tabs__body[aria-expanded="true"]')
      .filter(function () {
        return $(this).closest('.tabs')[0] === $tabs[0];
      })
      .removeClass('is-active')
      .filter('.tabs__body')
      .attr('aria-expanded', 'false')
      .end()
      .end()
      .end()
      .find('[aria-controls="' + $this.attr('aria-controls') + '"]')
      .addClass('is-active');

    $body.attr('aria-expanded', 'true').addClass('is-active');

    $(document).trigger('document-height-changed');
  }

  $(document).on('click.tabs', '.tabs__head', tabsHeadOnClick);
})(jQuery);
