$(function () {
  var $toolbar = $('[data-toolbar]');
  var $section = $(
    $toolbar
      .map(function () {
        return $(this).attr('href');
      })
      .get()
      .join(),
  );
  var timeout;

  $(window)
    .on('scroll', function () {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      timeout = window.requestAnimationFrame(function () {
        var distance = $(window).scrollTop();
        var noneFound = true;

        $section.each(function () {
          var $this = $(this);
          var id = $this.attr('id');

          if ($this.offset().top - 86 <= distance) {
            noneFound = false;
            $toolbar
              .filter('.is-active:not([href="#' + id + '"])')
              .removeClass('is-active')
              .end()
              .filter('[href="#' + id + '"]:not(.is-active)')
              .addClass('is-active');
          }
        });

        if (noneFound) {
          $toolbar.removeClass('is-active');
        }
      });
    })
    .trigger('scroll');
});
