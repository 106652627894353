(function ($) {
  var xhr;

  function autocomplete() {
    var $this = $(this),
      classes = ['form__autocomplete'];

    function source(term, suggest) {
      function done(response) {
        var result = [],
          i,
          l;

        switch ($this.data('autocomplete')) {
          case 'location':
            for (i = 0, l = response.length; i < l; i++) {
              var address = [];

              response[i].city && address.push(response[i].city);
              response[i].state && address.push(response[i].state);
              response[i].zip && address.push(response[i].zip);

              result.push(address.join(', '));
            }
            break;
          case 'state':
            for (i = 0, l = response.length; i < l; i++) {
              result.push(response[i].name + ' (' + response[i].code + ')');
            }
            break;
        }

        suggest(result);
      }

      try {
        xhr.abort();
      } catch (e) {}
      xhr = $.get($this.data('autocomplete-url'), { q: term }).done(done);
    }

    if ($this.hasClass('form__input--naked')) {
      classes.push('form__autocomplete--naked');
    }
    if ($this.hasClass('form__input--negative')) {
      classes.push('form__autocomplete--negative');
      classes.push('u-negative');
    }
    if ($this.hasClass('form__input--join--l')) {
      classes.push('form__autocomplete--join--l');
    }
    if ($this.hasClass('form__input--join--r')) {
      classes.push('form__autocomplete--join--r');
    }

    $this.autoComplete({
      source: source,
      menuClass: classes.join(' '),
      onSelect: function (event, term, item) {
        $this
          .closest('form:not([novalidate])')
          .attr('novalidate', true)
          .delay(10)
          .queue(function (next) {
            $(this).removeAttr('novalidate');
            next();
          });
      },
    });
  }

  function autocompleteOnSubmit(event) {
    event.stopImmediatePropagation();
    return false;
  }

  function init() {
    $('.autocomplete-suggestions').remove();
    $(this).autoComplete('destroy').each(autocomplete);
  }

  $(document)
    .on(
      'submit.autocomplete',
      'body:has(.autocomplete-suggestions:visible) form:has([data-autocomplete]:not([data-autocomplete-autosubmit]))',
      autocompleteOnSubmit,
    )
    .on('autocomplete-init', '[data-autocomplete]', init);

  $(function () {
    $('[data-autocomplete]').each(autocomplete);
  });
})(jQuery);
