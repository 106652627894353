/**
 * @author Sergio Agosti <sagosti@theframeworks.com>
 * @requires jQuery 1.10.2+
 *
 * Animate any CSS property even when the value is not defined or not numeric.
 * .has-transitions defines the CSS transitions on the element.
 * .is-transitioned defines the CSS properties to transition to.
 *
 * Example: transition the height of an element from 0 to auto and vice-versa.
 *
 * <div class="dropdown">
 *    <p>Donec ullamcorper nulla non metus auctor fringilla.</p>
 * </div>
 *
 * .dropdown {
 *     overflow: hidden;
 * }
 * .dropdown.has-transitions {
 *     transition: height 300ms;
 * }
 * .dropdown:not(.is-transitioned) {
 *     height: 0;
 * }
 */
(function ($) {
  'use strict';

  $.fn.transitionCSS = function (params, callback) {
    var defaults = {
      hasTransitionsCSSClass: 'has-transitions',
      isTransitionerCSSClass: 'is-transitioned',
      changeStatusCB: function (options) {
        $(this).toggleClass(options.isTransitionerCSSClass);
      },
    };

    return this.each(function () {
      var $element = $(this),
        options = defaults;

      if (typeof params === 'object') {
        options = $.extend(options, params);
      }

      if (typeof params === 'string') {
        options.isTransitionerCSSClass = params;
      }

      if (typeof params === 'function') {
        callback = params;
      }

      (function () {
        var element = this,
          properties = [],
          oldProperties = {},
          newProperties = {},
          isTransitionNeeded = false,
          i,
          newValue,
          oldValue,
          property;

        // add the class which defines the transition properties to the element
        $element.addClass(options.hasTransitionsCSSClass);

        // get the transition properties and save them into an array
        properties = $element.css('transition-property').split(', ');

        // remove the class which defines the transition properties so we can get the
        // computed values for the properties after the transition would have happened
        $element.removeClass(options.hasTransitionsCSSClass);

        // get the computed values for the transition properties
        // and save them into the oldProperties array
        for (i = 0; i < properties.length; i += 1) {
          oldProperties[properties[i]] = $element.css(properties[i]);
        }

        // change the status of the element
        options.changeStatusCB.apply(this, [options]);

        // get the computed values for the transition properties once the status has changed
        // and save them into the newProperties array
        for (property in oldProperties) {
          if (oldProperties.hasOwnProperty(property)) {
            newValue = $element.css(property);
            oldValue = oldProperties.property;

            if (oldValue !== newValue) {
              isTransitionNeeded = true;
              newProperties[property] = newValue;
            } else {
              delete oldProperties.property;
            }
          }
        }

        if (isTransitionNeeded) {
          // apply the initial css values for the transition properties
          $element.css(oldProperties);

          // setTimeout needed to allow certain browsers to apply oldProperties
          setTimeout(function () {
            // add the class to enable transitions and apply the final css values
            // for the transition properties
            $element.addClass(options.hasTransitionsCSSClass).css(newProperties);
          }, 10);
        } else {
          callback && callback.apply(element);
        }

        // clear the css values injected on the element once the transition is completed
        $element.one('transitionend', function () {
          $element.removeClass(options.hasTransitionsCSSClass);

          $.each(newProperties, function (property, value) {
            $element.css(property, '');
          });

          callback && callback.apply(element);
        });
      }).apply(this);
    });
  };
})(jQuery);
